@use "../../sass/partials/variables" as *;
@use "../../sass/partials/mixins" as *;

.chat {
	height: 100%;
	background-color: rgba($color: #333, $alpha: 0.5);
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: $app-padding;

	&__text {
		display: flex;
		flex-direction: column-reverse;
		@include invisible-vertical-scroll;
	}

	&__form {
		flex-grow: 1;
		display: flex;
	}

	&__input {
		display: flex;
		align-items: center;
	}

	&__input-field {
		background-color: transparent;
		margin-left: 0.5rem;
		padding: 0.5rem;
		font-size: 1.2rem;
		width: 6.5rem;
		height: 2rem;
		border: none;
		border-radius: 3px;
		color: $white;

		&:focus,
		&:focus-visible {
			outline: none;
		}

		&::placeholder {
			font-size: 1.2rem;
			font-weight: bold;
			color: #333;
			text-shadow: $text-shadow-dark;
		}
		flex-grow: 1;
	}
}

.button-chat {
	@include button;
}
