@use "../../sass/partials/variables" as *;
@use "../../sass/partials/functions" as *;

.HeaderFrame {
	background-color: frameColor(header);
	border: $frame-border;
	position: fixed;
	top: 0;
	width: 100%;
	height: 8vh;
	z-index: 0;

	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: $app-padding;
}

.logo {
	&__title {
		text-shadow: $text-shadow-white;
		cursor: pointer;
	}
}
