@use "../../../sass/partials/variables" as *;
@use "../../../sass/partials/mixins" as *;

.note-form {
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 0.25rem;
	color: $white;

	&__header {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		border-bottom: ridge $white 2px;
	}

	&__title {
		@include invisible-vertical-scroll;
		background-color: transparent;
		color: inherit;
		border: none;
		outline: none;
		resize: none;
		width: 100%;
		font-size: 1.1rem;
		padding: 0.3rem 1rem 0.3rem 0;
		height: 2rem;
		margin-left: auto;
	}

	&__note {
		@include invisible-vertical-scroll;
		color: inherit;
		background-color: transparent;
		outline: none;
		border: none;
		resize: none;
		height: 100%;
		padding-top: 1rem;
	}

	&__actions {
		display: flex;
		gap: 0.5rem;
	}

	&__save-note {
		@include icon-button;
	}

	&__show-archive {
		@include icon-button;

		&:hover {
			color: $error;
			filter: drop-shadow(0px 0px 3px $error);
		}
	}
}
