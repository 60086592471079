@use "./variables" as *;

@mixin tablet-breakpoint {
	@media (min-width: 780px) {
		@content;
	}
}

// TODO Add function for icon to pass color for shadow effects corisponding to color;
@mixin icon {
	filter: drop-shadow(0px 0px 3px $white);
	font-size: 1rem;
	display: block;
	position: relative;
	margin: 0.2rem 0;
	cursor: pointer;

	&:hover {
		filter: drop-shadow(0px 0px 3px $success);
	}
}

@mixin icon-button {
	border-radius: $border-raduis;
	padding: 0.1rem 0.3rem;
	display: flex;
	cursor: pointer;

	&:hover {
		color: $success;
		filter: drop-shadow(0px 0px 5px $success);
	}
}

@mixin button {
	margin-right: 0.5rem;
	padding: 0 0.5rem;
	width: 6.5rem;
	height: 2rem;
	border-radius: 3px;
	font-size: 1.1rem;
	font-weight: bold;
	cursor: pointer;

	&:hover {
		color: $success;
		font-weight: normal;
		filter: drop-shadow(0px 0px 5px $success);
	}
}

@mixin invisible-vertical-scroll {
	overflow-y: scroll;
	//firefox
	scrollbar-width: none;
	// Edge
	-ms-overflow-style: none;
	//chrome, safari, oprea
	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin toggle-switch {
	font-size: 0.5rem;
	appearance: none;
	outline: 0;
	width: 6em;
	height: 3em;
	border-radius: 3em;
	cursor: pointer;
	background-color: $light-grey;
	position: relative;

	&::after {
		content: "";
		width: 3em;
		height: 3em;
		border-radius: 3em;
		position: absolute;
		background-color: $white;
		transform: scale(0.75);
		left: 0;
		transition: left 90ms ease-in-out;
		box-shadow: 0 0.1em rbga($black, 0.4);
	}

	&:checked {
		background-color: $success;

		&::after {
			left: 3em;
		}
	}
}
