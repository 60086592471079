@use "../../sass/partials/variables" as *;
@use "../../sass/partials/functions" as *;

.SideFrame {
	// Style
	background-color: frameColor(aside);
	border: $frame-border;

	// Position
	position: fixed;
	top: 8vh;
	right: 0;
	z-index: 0;

	// Sizing
	width: 25%;
	height: 65vh;

	// Children
	padding: 1rem;
}
