@use "../../sass/partials/variables" as *;
@use "../../sass/partials/mixins" as *;

.note-section {
	background-color: $app-background-dark;
	border-radius: $border-raduis;
	padding: 0.5rem 1rem;
	height: 100%;
	overflow: hidden;

	&__header {
		position: relative;
		text-shadow: $text-shadow-white;
		display: flex;
		align-items: center;
	}

	&__archive {
		height: 100%;
	}

	&__add-note {
		@include icon-button;
		margin-left: auto;
	}

	&__history {
		@include invisible-vertical-scroll;
		height: 95%;
	}

	&__info {
		text-align: center;
		margin: auto;
		padding-top: 3rem;
		display: grid;
		gap: 1rem;
	}
}
