@use "../../sass/partials/variables" as *;
@use "../../sass/partials/functions" as *;

.InformationFrame {
	background-color: frameColor(information);
	border: $frame-border;
	position: fixed;
	bottom: 0;
	width: 75%;
	height: 16vh;
	z-index: 0;

	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 0 1rem;
}
