@use '../../../sass/partials/variables' as *;

.stacklist {
	padding-left: 2rem;

	&__item {
		color: black;
		font-size: 1.2rem;
		text-shadow: $text-shadow-dark;
		border-radius: 2px;

		&:hover {
			background-color: rgb(141, 44, 44);
		}
	}

	&__link {
		padding-left: 0.25rem;
		text-decoration: none;
		color: black;
	}
}
