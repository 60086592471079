@use '../../sass/partials/variables' as *;

.DMs {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&__User-List {
		position: absolute;
		top: -1rem;
		left: -1rem;
		display: flex;
		gap: 0.5rem;
		padding: 0 0.1rem;
		height: 1rem;
		margin-bottom: auto;
		width: calc(100% + 2rem);
		background-color: black;
	}

	&__User {
		font-size: 0.875rem;
		padding: 0 0.25rem;
		background-color: red;
		cursor: pointer;
		&:hover {
			font-weight: bold;
		}

		&--online {
			background-color: green;
		}
	}

	&__Messages {
		margin-top: 0.25rem;
		display: flex;
		flex-direction: column-reverse;
		gap: 0.25rem;
		overflow-y: scroll;
		//firefox
		scrollbar-width: none;
		// Edge
		-ms-overflow-style: none;
		//chrome, safari, oprea
		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__Message {
		width: 90%;
		padding: 0.15rem;
		font-size: 0.875rem;
		line-height: 1;
		border-radius: 5px;
		background: rgba($color: black, $alpha: 0.5);
		text-shadow: $text-shadow-white;

		&--self {
			background: rgba($color: red, $alpha: 0.5);
			text-align: right;
			align-self: flex-end;
		}
	}

	&__Form {
		margin-top: 0.25rem;
		display: flex;
	}

	&__Input {
		color: $black;
		background: rgba($color: white, $alpha: 0.3);
		font-size: 1.2rem;
		width: 6.5rem;
		height: 2rem;
		border: none;
		border-radius: 3px;
		color: $white;

		&:focus,
		&:focus-visible {
			outline: none;
		}
		flex-grow: 1;
	}

	&__Submit {
		background: rgba($color: white, $alpha: 0.3);
		color: $black;
		padding: 0.15rem;
		border: none;
		border-radius: 5px;
		margin-left: 0.25rem;
	}
}
