@use "../../../sass/partials/variables" as *;
@use "../../../sass/partials/mixins" as *;

.task-list {
	text-decoration: none;
	list-style: none;
}

.task {
	margin: 1rem 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	&__text {
		text-shadow: $text-shadow-white;
		cursor: pointer;

		&--done {
			cursor: pointer;
			text-shadow: none;
			text-decoration: line-through;
			color: $light-grey;
		}
	}

	&__actions-container {
		display: none;
		margin-left: 1rem;
		&--show {
			height: auto;
			display: flex;
			gap: 0.5rem;
		}
	}

	&__action {
		@include icon;
		font-size: 0.7rem;

		&:hover {
			color: $error;
		}

		&--edit {
			&:hover {
				color: $success;
			}
		}
	}
}
