@use "../../sass/partials/variables" as *;
@use "../../sass/partials/functions" as *;

.MainFrame {
	// Style
	background-color: frameColor(main);
	border: $frame-border;

	// Position
	position: fixed;
	top: 8vh;
	z-index: 0;

	// Size
	width: 75%;
	height: 76vh;

	// Children
	padding: 1rem;
}
