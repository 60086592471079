// Colors
$white: #f5f5f5; // White
$blue: #96bcce; // blue
$black: #181a18; // Black
$light-grey: #c4c3c3; // grey
$success: #14b914; //green
$error: #cc0000; // error
$alpha: 0; //

// Background dark
$app-background-dark: rgba($black, 0.55);
$app-background-light: rgba($light-grey, 0.45);

// Shadows
$text-shadow-white: 0 0 10px $white, 0 0 10px $black;
$text-shadow-dark: 0 0 10px $black, 0 0 10px $white;
$text-shadow-green: 0 0 10px $success, 0 0 10px lime;

// Borders
$border-raduis: 0.25rem;
$frame-border: none;

// Padding
$app-padding: 0.5rem 1rem;

// Maps
$frame-colors: (
	header: rgba(transparent, $alpha),
	main: rgba(transparent, $alpha),
	aside: rgba(transparent, $alpha),
	information: rgba(transparent, $alpha),
	user: rgba(transparent, $alpha),
	modal: rgba($light-grey, 0.25),
);
