@use "../../sass/partials/variables" as *;
@use "../../sass/partials/mixins" as *;

.radio {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__player {
		display: none;
		position: absolute;
	}

	&__title {
		text-align: center;
		text-shadow: $text-shadow-white;
	}
	&__button {
		@include button;
	}
}
