@use "../../sass/partials/variables" as *;
@use "../../sass/partials/mixins" as *;

.user {
	&__navigation {
		display: flex;
	}

	&__icon {
		@include icon;
	}

	&__icon-container {
		margin: 0 0.4rem;
		cursor: pointer;
		position: relative;
		text-align: center;

		&:hover {
			filter: drop-shadow(0px 0px 3px $success);
		}
	}

	&__icon-title {
		font-size: 0.8rem;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		color: $success;
		text-shadow: $text-shadow-white;
	}
}
