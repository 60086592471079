@use '../../sass/partials/variables' as *;

.toolbar {
	&__time {
		font-size: 1.5rem;
		text-shadow: $text-shadow-white;
	}

	&__day {
		text-shadow: $text-shadow-white;
	}
}
