@use "../sass/partials/variables" as *;

.parent-container {
	position: relative;
}

.gifs {
	position: fixed;
	object-fit: cover;
	z-index: -1;
	width: 100vw;
	height: 100vh;
}
