@use '../../sass/partials/variables' as *;

.stack {
	background-color: rgba($color: #ccc, $alpha: 0.5);
	border-radius: 3px;
	padding: 0.5rem;

	&__title {
		margin-left: 0.25rem;
		color: white;
		text-shadow: $text-shadow-white;
	}

	&__button-container {
		display: flex;
		align-items: center;
		font-size: 1.2rem;
		text-shadow: $text-shadow-white;
	}

	&__button {
		margin: 0.25rem;
		width: 6.5rem;
		height: 2rem;
		border: none;
		border-radius: 3px;
		background-color: rgba($color: #ccc, $alpha: 0.25);
		font-size: 1.2rem;
		font-weight: bold;
		cursor: pointer;
		text-shadow: $text-shadow-dark;

		&--selected {
			background-color: rgba($color: rgb(19, 107, 24), $alpha: 0.75);
		}
	}
}
